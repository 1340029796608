@tailwind utilities;

@layer utilities {
	.text-shadow-none {
		text-shadow: none;
	}
	.grid-auto-rows-min {
		grid-auto-rows: min-content;
	}
}

html,
body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
		Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	@apply text-neutral-900 text-base;
}

* {
	box-sizing: border-box;
}

*,
::before,
::after {
	box-sizing: border-box; /* 1 */
	border-width: 0; /* 2 */
	border-style: solid; /* 2 */
	border-color: theme("borderColor.DEFAULT"); /* 2 */
}

/* Walletconnect modal */
#w3m-modal {
	z-index: 1001;
}

#__next {
	@apply grid grid-rows-layout h-full;
}

#terms-modal p {
	@apply leading-relaxed;
}

#terms-modal sup a {
	@apply text-xs;
}

/* Antdesign overrides */
.ant-typography {
	@apply text-neutral-900 text-base;
}

.ant-form-item-label > label {
	/* Unset label color so it displays the correct color when disabled */
	color: unset !important;
	/* Unset label font size so it displays the correct size that get set on the parent */
	font-size: unset !important;
}

.ant-input-wrapper.ant-input-group > .ant-input-group-addon {
	@apply bg-neutral-0 py-4 px-[14.5px] border-neutral-400 rounded border border-solid text-base font-normal !important;
}

.ant-input-wrapper.ant-input-group > .ant-input-group-addon:first-child {
	@apply rounded-r-none border-r-0 !important;
}

.ant-input-wrapper.ant-input-group > .ant-input-group-addon:last-child {
	@apply rounded-l-none border-l-0 !important;
}

.ant-input-wrapper.ant-input-group > .ant-input-affix-wrapper-lg {
	@apply h-full !important;
}

.ant-input-affix-wrapper {
	@apply border rounded rounded-l-none border-neutral-400 !important;
}
.ant-input-number-input,
.ant-input-affix-wrapper.ant-input-affix-wrapper-lg .ant-input {
	@apply bg-inherit font-medium !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	@apply border border-neutral-600 !important;
}

.ant-input-group-wrapper {
	@apply p-0 !important;
}

.anticon-close-circle {
	/* Don't show error icon in input fields with validation errors */
	display: none !important;
}

.ant-input-number-input,
.ant-form-item-control-input-content .ant-input {
	@apply font-medium text-base placeholder-neutral-900/70 !important;
}

.ant-form-item-control-input-content .ant-input-suffix {
	@apply ml-0 !important;
}

.ant-spin-dot-item {
	@apply bg-neutral-800 !important;
}

.ant-message {
	@apply h-full top-0 grid place-items-center bg-neutral-800/50 !important;
}

.ant-message-notice {
	@apply flex justify-center font-mono !important;
}

.ant-message-notice-content {
	@apply px-7 py-5 shadow-none font-bold text-base border-[0.1px] rounded border-solid border-neutral-600 bg-neutral-50 !important;
}

.ant-message-custom-content {
	@apply flex items-center space-x-3 !important;
}

.anticon {
	@apply text-current !important;
}

.ant-input-group-addon {
	@apply bg-neutral-0 !important;
}

.ant-input-affix-wrapper {
	@apply border border-neutral-400 !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
	@apply ring-1 ring-neutral-600 border-neutral-600 shadow-none z-10 !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	@apply border-r !important;
}
.ant-input-affix-wrapper:hover {
	@apply border-r border-neutral-600 !important;
}

.ant-input-group.ant-input-group-compact {
	@apply flex !important;
}

.deal-repay-input .ant-input {
	@apply text-2xl font-bold !important;
}

.label-hidden > .ant-form-item-label {
	@apply invisible !important;
}

.ant-form-item-label:has(".label-hidden") {
	@apply invisible !important;
}

.ant-modal-content {
	@apply bg-inherit text-inherit !important;
}

.ant-modal-close {
	@apply text-inherit !important;
}

.ant-modal-close-x {
	@apply grid place-content-center;
}

.ant-modal-body {
	@apply px-10 pb-0 pt-10 !important;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
	@apply bg-transparent !important;
}

.wallet-adapter-modal-container {
	@apply items-start;
}

.wallet-adapter-modal-wrapper {
	@apply top-12;
}

.ant-modal-footer {
	border-top: none !important;
	@apply p-10 !important;
}

[ant-click-animating-without-extra-node="true"]::after,
.ant-click-animating-node {
	@apply shadow-none !important;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
	color: #e6e8e8 !important;
}

.ant-notification-notice-message {
	@apply leading-loose mb-0 !important;
}

.ant-tooltip {
	@apply max-w-md;
}

.ant-tooltip-inner {
	@apply px-6 py-4;
	@apply bg-neutral-800;
}

.input-fraction .hidden-value-input .ant-form-item-control-input {
	@apply hidden !important;
}

/* TODO: Move the following classes to the components package when moving the tsx file*/

.crdx-pool-card.crdx-card.ant-card .ant-card-head {
	@apply bg-neutral-150 px-8;
}

.crdx-pool-card.crdx-card.ant-card .ant-card-body {
	@apply px-8;
}

.crdx-pool-card.crdx-card.ant-card .ant-card-actions {
	@apply border-t-0 px-6 pb-8;
}

.crdx-pool-card.crdx-card.ant-card .ant-card-actions li {
	@apply my-0;
}

.crdx-pool-card.crdx-card.ant-card .ant-card-head .ant-card-head-title,
.crdx-pool-card.crdx-card.ant-card .ant-card-head .ant-card-extra {
	@apply py-6;
}

.crdx-pool-card-meta.crdx-card.ant-card .ant-card-body {
	@apply p-0;
}

.crdx-pool-card-meta.crdx-card.ant-card .ant-card-meta {
	@apply items-center mx-2 my-1;
}

.crdx-pool-card-meta.crdx-card.ant-card .ant-card-meta .ant-card-meta-avatar {
	@apply pr-2;
}
